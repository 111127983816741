/* You can add global styles to this file, and also import other style files */
/*@import 'https://library-sdb.apps.bancolombia.com/bds.min.css';*/
@import 'https://library-sdb.apps.bancolombia.com/bds/6.40.1/bds.min.css';


body {
  margin: 0px;
  padding: 0px;
  background: url('/assets/images/05.svg') center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: 100%;
  background-position: center;
}

.home {
  margin: 0px;
  padding: 0px;
  background: url('/assets/images/11.svg') center bottom;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: 100%;
  background-position: bottom;
}

.product-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
}

.contenedor {
  background-color: white;
  align-items: center;
}

.step {
  width: 45%;
  margin: auto;
  margin-top: 10vh;
  min-width: 200px;
}

.texto {
  text-align: center;
}

.texto h4 {
  margin-top: 51px;
  margin-bottom: 27px;
}

.boton {
  margin-top: 5vh;
  width: min-content;
}

.cancelar {
  text-align: center;
  margin-top: 30px;
}

.cancelar .bc-link {
  margin-right: 0%;
}

.cancelar__content {
  width: 100%;
  margin-left: 0%;
  margin-right: 0%;
  padding: 0%
}